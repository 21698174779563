import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileStatisticsComponent } from './profile-statistics.component';
import { CommonMaterialModule } from "../common-material/common-material.module";



@NgModule({
  declarations: [
    ProfileStatisticsComponent
  ],
  imports: [
    CommonModule,
    CommonMaterialModule
  ],
  exports:[
    ProfileStatisticsComponent
  ]
})
export class ProfileStatisticsModule { }
