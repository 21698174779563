import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './search-bar.component';
import { FormsModule } from '@angular/forms';
import { IconsModule } from '../common/icons/icons.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedDirectivesModule } from '../shared-directives/shared-directives.module';

@NgModule({
  declarations: [SearchBarComponent],
  imports: [CommonModule, FormsModule, IconsModule, MatTooltipModule, SharedDirectivesModule],
  exports: [SearchBarComponent],
})
export class SearchBarModule {}
