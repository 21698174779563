import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TradeCreatorPageComponent } from './trade-creator-page.component';
import { TradeCreatorModalComponent } from './trade-creator-modal/trade-creator-modal.component';
import { TradeCreatorComponent } from './trade-creator/trade-creator.component';
import { TradeCreatorFormComponent } from './trade-creator-form/trade-creator-form.component';
import { TradeCreatorPreviewComponent } from './trade-creator-preview/trade-creator-preview.component';
import { TradeCreatorCompleteComponent } from './trade-creator-complete/trade-creator-complete.component';
import { TradeCreatorTableComponent } from './trade-creator-table/trade-creator-table.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '../common/icons/icons.module';
import { TabSelectorModule } from '../common/tab-selector/tab-selector.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SearchBarModule } from '../search-bar/search-bar.module';
import { SharedDirectivesModule } from '../shared-directives/shared-directives.module';
import { ProfileCardsModule } from '../common/profile-cards/profile-cards.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonMaterialModule } from '../common/common-material/common-material.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    TradeCreatorPageComponent,
    TradeCreatorModalComponent,
    TradeCreatorComponent,
    TradeCreatorFormComponent,
    TradeCreatorPreviewComponent,
    TradeCreatorCompleteComponent,
    TradeCreatorTableComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    IconsModule,
    TabSelectorModule,
    BsDropdownModule.forRoot(),
    SearchBarModule,
    SharedDirectivesModule,
    ProfileCardsModule,
    ModalModule.forRoot(),
    CommonMaterialModule,
    MatDialogModule,
  ],
  exports: [TradeCreatorModalComponent],
})
export class TradeCreatorPageModule {}
