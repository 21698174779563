export const environment = {
  production: true,
  uploadImageHostname: 'node.deso.org',
  verificationEndpointHostname: 'https://node.deso.org',
  uploadVideoHostname: 'node.deso.org',
  identityURL: 'https://identity.deso.org',
  supportEmail: '',
  dd: {
    apiKey: 'DCEB26AC8BF47F1D7B4D87440EDCA6',
    jsPath: 'https://bitclout.com/tags.js',
    ajaxListenerPath: 'bitclout.com/api',
    endpoint: 'https://bitclout.com/js/',
  },
  amplitude: {
    key: '',
    domain: '',
  },
  node: {
    id: 1,
    name: 'DeSo',
    url: 'https://node.deso.org',
    logoAssetDir: '/assets/deso/',
  },
  socketUrl: 'localhost:8000',
  postOrigin: 'https://cricket.social',
  csBackendStandalone: 'https://backend-prod.cricket.social/v1/',
  //csBackendStandalone: "https://cs-backend-standalone-4wdp3hqc4q-uc.a.run.app/v1/",
  firebase: {
    apiKey: 'AIzaSyDG49F0PdV3juTpbmcjXihhG3TvMTJvsN4',
    authDomain: 'numeric-camp-346404.firebaseapp.com',
    projectId: 'numeric-camp-346404',
    storageBucket: 'numeric-camp-346404.appspot.com',
    messagingSenderId: '646418269518',
    appId: '1:646418269518:web:5718a445b72209fef9ca1f',
    measurementId: 'G-DPQTT7HMVS',
  },
};
