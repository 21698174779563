import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-header-layout',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.scss']
})
export class HeaderLayoutComponent implements OnInit {

  @Input() title:string;
  @Output() layoutEvent:EventEmitter<any> = new EventEmitter<any>();
  layoutCtrl:FormControl = new FormControl('grid');

  constructor() { }

  ngOnInit(): void {
    this.layoutCtrl.valueChanges.subscribe(value => {
      this.layoutEvent.emit(value);
    })
  }

}
