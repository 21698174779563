import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from "@angular/common";

@Pipe({
  name: 'distanceMetric'
})
export class DistanceMetricPipe implements PipeTransform {

  constructor(private _decimalPipe: DecimalPipe){

  }
  transform(value: number, ...args: unknown[]): string {
    let formatDistance = null;
    switch (true) {
      case value == 0 : {
        formatDistance  = null;
        break;
      }
      case value < 1000 : {
         formatDistance = this._decimalPipe.transform(value,  '1.0-0') + ' M';
         break;
      }
      default: {
        formatDistance = this._decimalPipe.transform( value/1000 ,  '1.0-0') + ' KM';
        break;
      }
    }
    return formatDistance;
  }

}
