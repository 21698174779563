import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpService } from "../../../services/common/http.service";
import { CommonService } from "../../../services/common/common.service";
import { GlobalVarsService } from "../../../services/deso/global-vars.service";
import { BackendApiService, ProfileEntryResponse } from "../../../services/deso/backend-api.service";
import { Subscription, zip } from "rxjs";
import { FollowChangeObservableResult } from "../../../../lib/observable-results/follow-change-observable-result";
import { map } from "rxjs/operators";

@Component({
  selector: 'app-profile-statistics',
  templateUrl: './profile-statistics.component.html',
  styleUrls: ['./profile-statistics.component.scss']
})
export class ProfileStatisticsComponent implements OnInit {

  @Input() userName:string;
  @Output() profileEvent:EventEmitter<ProfileEntryResponse> = new EventEmitter<ProfileEntryResponse>();
  loading:boolean;
  profile: ProfileEntryResponse;
  followChangeSubscription: Subscription;
  followerCount: number = null;
  followingCount: number = null;
  refreshFollowingBeingCalled = false;

  constructor(
    public httpService: HttpService,
    public commonService: CommonService,
    private globalVars: GlobalVarsService,
    private backendApi: BackendApiService,
  ){}
  ngOnInit(): void {
    this._refreshContent();
  }



  _refreshContent() {
    if (this.loading || !this.userName) {
      return;
    }
    this.loading = true;
    this.backendApi.GetSingleProfile(this.globalVars.localNode, '', this.userName).subscribe((res) => {
        if (!res || res.IsBlacklisted) {
          this.loading = false;
          // this.router.navigateByUrl("/" + this.appData.RouteNames.NOT_FOUND, { skipLocationChange: true });
          return;
        }
        this.profile = res.Profile;
        this.profileEvent.emit(this.profile);
        // If the user follows/unfollows this user, update the follower count
        this.followChangeSubscription = this.globalVars.followChangeObservable.subscribe(
          (followChangeObservableResult) => {
            this._handleFollowChangeObservableResult(followChangeObservableResult);
          }
        );
        this._refreshFollowing();
      }, (error) => {
        this.httpService.alertError('Error While Getting Statistics Data', this);
      }
    );
  }


  _handleFollowChangeObservableResult(followChangeObservableResult: FollowChangeObservableResult) {
    if (followChangeObservableResult.followedPubKeyBase58Check === this.profile.PublicKeyBase58Check) {
      if (followChangeObservableResult.isFollowing) {
        this.followerCount += 1;
      } else {
        this.followerCount -= 1;
      }
      this.loading = false;
    }
  }

  _refreshFollowing() {
    if (this.refreshFollowingBeingCalled) {
      this.loading = false;
      return;
    }
    this.refreshFollowingBeingCalled = true;
    const getFollowers = this.backendApi.GetFollows(
        this.globalVars.localNode,
        this.profile.Username,
        '' /* PublicKeyBase58Check */,
        true /* get followers */,
        '' /* GetEntriesFollowingUsername */,
        0 /* NumToFetch */
      ).pipe(map((res) => res.NumFollowers));
    const getFollowing = this.backendApi.GetFollows(
        this.globalVars.localNode,
        this.profile.Username,
        '' /* PublicKeyBase58Check */,
        false /* get following */,
        '' /* GetEntriesFollowingUsername */,
        0 /* NumToFetch */
      ).pipe(map((res) => res.NumFollowers));
    zip(getFollowers, getFollowing).subscribe((res) => {
        this.followerCount = res[0];
        this.followingCount = res[1];
        this.refreshFollowingBeingCalled = false;
        this.loading = false;
      },
      (error) => {
        this.refreshFollowingBeingCalled = false;
        console.error(error);
        this.loading = false;
      }
    );
  }


  getFoundersRewardPercent() {
    if(!this.profile) {
      return;
    }
    return this.profile.CoinEntry.CreatorBasisPoints / 100;
  }
}
