<div class="row playersHeader">
  <div class="col-lg-4 col-sm-12">
    <div class="pageTitle" style="margin-top: 20px">{{title}}</div>
  </div>
  <div class="col-lg-8 col-sm-12" style="text-align: right">
    <mat-button-toggle-group name="layout" aria-label="layout" [formControl]="layoutCtrl">
      <mat-button-toggle value="grid" [matTooltip]="'layout.grid' | translate"><mat-icon>grid_on</mat-icon></mat-button-toggle>
      <mat-button-toggle value="list" [matTooltip]="'layout.list' | translate"><mat-icon>list</mat-icon></mat-button-toggle>
      <mat-button-toggle value="map" [matTooltip]="'layout.map' | translate"><mat-icon>map</mat-icon></mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
