<div class="centerContainer statistics">
  <div style="cursor: pointer" (click)="httpService.navigate('../followers', {userName: userName, tabName: 'followers'})">
    <span *ngIf="!loading">{{followerCount}}</span>
    <span class="SKELETON" *ngIf="loading"></span>
    <span>{{'Followers' | translate}}</span>
  </div>
  <div style="cursor: pointer" (click)="httpService.navigate('../followers', {userName: userName, tabName: 'following'})">
    <span *ngIf="!loading">{{followingCount}}</span>
    <span class="SKELETON" *ngIf="loading"></span>
    <span>{{'Following' | translate}}</span>
  </div>
  <div>
    <span *ngIf="!loading">{{ getFoundersRewardPercent() | number: "0.0-9" }}%</span>
    <span class="SKELETON" *ngIf="loading"></span>
    <span>FR</span>
  </div>
</div>
